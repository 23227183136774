import React, {useEffect, useState} from "react"
import { graphq, Link } from "gatsby"
import Layout from '../components/layout'
import view from "../components/blogPage/blog.module.scss";


const SingleCategory = (props) => {
  const {pageContext} = props;
  const tag = pageContext.tagName;
  const posts = pageContext.searchedPosts;
  const blogManagement = pageContext.blogmanagement;
  const allBlogPagesBottomHTML = blogManagement.allBlogPagesBottomHTML;
  const categoriesBlogResultsHTML = blogManagement.categoriesBlogResultsHTML;
  const blogResultsTopHTML = blogManagement.blogResultsTopHTML;
  const pageSeo = {seo:{
    MetaDesc: `Explore the latest blog posts and updates from ${pageContext.dealerName} & stay informed by reading our latest news & tips about ${tag}.`,
    MetaTitle: `${tag} Blog Posts | ${pageContext.dealerName}`,
    tags: []}
  }

    // function to format publication date to display Month, Day, Year
    function formatDate(inputDate) {
      const date = new Date(inputDate);
      if (isNaN(date.getTime())) {
          // Invalid date format, return input as is
          return inputDate;
      }

      const options = { 
          month: 'long', 
          day: 'numeric', 
          year: 'numeric' 
      };

      return date.toLocaleDateString(undefined, options);
    }

    // function to shorten blog title to specified number of characters and add elipsis at the end
    function truncateString(str, maxLength) {
        if (str.length <= maxLength) {
            return str;
        } else {
            return str.slice(0, maxLength) + '...';
        }
    }

    // function to convert various date formats to mm/dd/yyyy
    function convertDate(dateString) {
      const date = new Date(dateString);
      if (!isNaN(date) && /^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
        // Date is already in the preferred format
        return dateString;
      } else if (!isNaN(date)) {
        // convert the number to a Date object and format it
        const formattedDate = new Date(date);
        return (formattedDate.getMonth() + 1).toString().padStart(2, '0') + '/' + formattedDate.getDate().toString().padStart(2, '0') + '/' + formattedDate.getFullYear();
      } else {
        // try to parse other formats
        const parsedDate = Date.parse(dateString);
        if (!isNaN(parsedDate)) {
          const parsed = new Date(parsedDate);
          // convert to preferred format
          return (parsed.getMonth() + 1).toString().padStart(2, '0') + '/' + parsed.getDate().toString().padStart(2, '0') + '/' + parsed.getFullYear();
        } else {
          // if unable to parse, return null
          return null;
        }
      }
    }

    // parse each content string to access the blogposts object
    posts.forEach(post => {
      const content = JSON.parse(post.content);
      post.blogposts = content.blogposts;
    });

    // convert the publicationDate of each post and set it as the value of convertedPublicationDate
    // if publicationDate fails to parse and returns as null, convertedPublicationDate will not be created
    posts.forEach(post => {
      const publicationDate = convertDate(post.blogposts.publicationDate);
      if (publicationDate !== null) {
        post.blogposts.convertedPublicationDate = publicationDate;
      }
    });
    
    // sort posts array by date
    posts.sort((a, b) => {
      const dateA = a.blogposts.convertedPublicationDate;
      const dateB = b.blogposts.convertedPublicationDate;

      // check if dates are valid and move them to the end if needed
      if (!dateA || !dateB) {
        if (!dateA && !dateB) {
          return 0; // if both dates are invalid, leave them where they are
        } else if (!dateA) {
          return 1; // if dateA is invalid, move it to the end
        } else {
          return -1; // if dateB is invalid, move it to the end
        }
      }

      // Sort valid dates in descending order (newest to oldest)
      return new Date(dateB) - new Date(dateA);
    });

  return(
    <Layout seo={pageSeo}>
      <div dangerouslySetInnerHTML={{ __html: blogResultsTopHTML }} />
      <div style={{ width: "95%", margin: '0 auto', maxWidth: 2000, padding: "0 15px", minHeight: "70vh"}}>
        <h1 className={`${view['category-page-header']}`}><span  className={`${view['tag-title']}`}>{tag}</span></h1>
        <ul style={{display: 'flex', flexWrap:'wrap', justifyContent: 'center', alignItems: 'stretch'}}>
          {posts.map(post => {
            
            const title = JSON.parse(post.pageTitle);
            const slug = JSON.parse(post.slug); 
            const content = JSON.parse(post.content);
            const id = JSON.parse(post._id);
            const parsedContent = content.blogposts;
            let formattedDate = formatDate(parsedContent.publicationDate);
            let truncatedTitle = truncateString(title, 100)

            return(
              <li className={view["category-card__container"]} key={id} >
                <Link className={view["category-card"]} href={slug}>
                  <div className={view["category-card__image"]}>
                    <div style={{backgroundImage:`url(${parsedContent.thumbnailImage ? parsedContent.thumbnailImage:null})`, backgroundSize:'cover', backgroundRepeat:'no-repeat', width:'100%', height:'100%', backgroundPosition: 'center'}}></div>
                  </div>
                  <div className={view["category-card__flexible"]} style={{paddingTop:10}}>
                    {parsedContent.publicationDate ? <h3 className={view["category-card-date"]}>{formattedDate}</h3> : null }
                    {title ? <p className={view["category-card-title"]}>{truncatedTitle}</p> : null }
                    <div className={view["category-card-read-more"]}>
                      <h3>READ MORE</h3>
                    </div>
                  </div>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
      {/*BLOG RESULTS DATA */}
      <div dangerouslySetInnerHTML={{ __html: categoriesBlogResultsHTML }} />

      {/* ALL BLOG DATA */}
      <div dangerouslySetInnerHTML={{ __html: allBlogPagesBottomHTML }} />
    </Layout>
  )
}

export default SingleCategory;